<script setup>
const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  colorCode:{
    type:String,
    default: 'currentColor'
  }
})

const iconComponent = defineAsyncComponent(() =>
  import(`./Icons/icon-${props.name}.vue`)
)
</script>

<template>
  <span class="icon svg" :class="`svg--${name}`, {'fillColor': colorCode == 'currentColor'}">
    <component :is="iconComponent" />
  </span>
</template>

<style lang="scss">
.svg {
  svg {
    display: block;
    width: 100%;
    height: 100%;
  }

  // Fixes for svg that are expanding for no reason on iOS
  &--circle {
    svg {
      width: unset;
      height: unset;
    }
  }
}
.fillColor {
  svg{
    fill: currentColor;
  }
}
</style>
